import axios from "axios";
import baseURL from "./index";
// import Cookies from "js-cookie";
// import Constants from "../constants";
// import {Toast} from 'vant';

import md5 from "js-md5";
var requesting = [];
const limitTime = 10000;
// const CancelToken = axios.CancelToken;
// let cFun;

export const service = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  responseType: "json",
  method: "post",
  // withCredentials: true, // 是否允许带cookie
  headers: {
    // "content-type": "application/json;charset=utf-8" // 会发option请求
    "content-type": "application/x-www-form-urlencoded" // 不会触发option请求
    // "content-type": "multipart/form-data" // 不会触发option请求
  }
});

// POST请求拦截 (对请求参数序列化)
service.interceptors.request.use(
  async config  => {
    const requestingId = md5.hex(JSON.stringify(config.url + config.data));

    // // `cancelToken` 指定用于取消请求的 cancel token
    // config.cancelToken = new CancelToken(function (c) {
    //   cFun = c;
    // });

    // 这段啥意思不明白
    if (config.method === "post") {
      const nowTime = new Date().getTime();
      requesting = requesting.filter(item => {
        return item.startTime + limitTime > nowTime;
      });
      const sessionUrl = requesting.filter(item => {
        return item.requestingId === requestingId;
      });
      if (sessionUrl.length > 0) {
        return config;
      }
      const item = {
        requestingId: requestingId,
        startTime: nowTime
      };
      requesting.push(item);
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 返回数据的判断校验
service.interceptors.response.use(
  response => {
    // 返回错误标志
    if (response.data && response.data.code !== 200) {
      if (response.data.code === 10000) {
        // cFun(response.data.msg);
      } else {
        console.log(response.data.msg)
        // Toast(response.data.msg)
      }
      return Promise.reject(new Error(response.data.msg));
    }
    return response.data;
  },
  error => {
    console.log(error);
    if (error.response) {
      return Promise.reject(error);
    }
  }
);

export default service;
