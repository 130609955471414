<template>
  <AppPage :name="'home'">
    <div class="main">
      <div class="content">
        <div class="category-list flex bg-white pl-25 pr-25" >
          <div v-for="(item, i) in categorys" v-bind:key="i" class="category-name ml-15 mr-15" :class="item == activeCategory.name ? 'active' : ''" @click="onCategoryNameClick(item)">{{item}}</div>
        </div>
        <div style="width: 100%; height: 1px; background: #f8f8f8;"></div>
        <div class="item-list full-width box-border" style="height: calc(100vh - 10rem);overflow: auto;" @scroll="onListScroll($event)">
          <div v-for="(e,i) in list" :key="i">
            <ListItem :item="e" />
            <div class="pl-25 pr-25">
              <div style="width: 100%; height: 1px; background-color: #f8f8f8"></div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="ml-20"></div>
    <div class="side">
      
      <AppQRCode />
      <Copyright />
    </div>
  </AppPage>
</template>
<script>
import {fetchCategorys,fetchList} from '@/api/modules/baoliao'
import ListItem from './components/ListItem.vue'
import Copyright from '../../components/Copyright.vue'
export default {
  name: "Home",
  components: {ListItem, Copyright},
  data() {
     return {
       loading: false,
       activeCategory: {
         name: "最热"
       },
       categorys: [],
       isLastPage: 0,
       listParams: {
         pageNo: 1,
         pageSize: 10,
         userId: 147938,
         tagId: 199,
       },
       list: []
     }
  },
  created(){
    fetchCategorys().then((res) => {
      this.categorys = res.data

      this.listParams['category'] = this.activeCategory.name
      this.queryData()
    })
  },
  computed:{
  },
  methods:{
    onCategoryNameClick(name){
      
      this.activeCategory.name = name
      this.activeCategoryClassName
      this.listParams['category'] = this.activeCategory.name
      this.list = []
      this.listParams.pageNo = 1
      this.queryData()
    },
    queryData() {
      var self = this

      this.loading = true

      fetchList(this.listParams).then((res) => {
        if(res.code == 200 && res.data) {
          self.list = self.list.concat(res.data.list)
          self.isLastPage = res.data.isLastPage
        }

        this.loading = false
      })
    },

    onListScroll(event){
      var ele = event.srcElement
      var scrollTop = ele.scrollTop,scrollHeight = ele.scrollHeight,elementHeight = parseInt(getComputedStyle(ele).height)
      
      if((scrollTop + elementHeight + 80) > scrollHeight) {
        
        if(!this.loading && !this.isLastPage) {
          this.listParams.pageNo += 1
          console.log(this.listParams.pageNo)
          this.queryData()
        }
      }
    }
  },
}
</script>
<style lang="css" scoped>
.main {
  width: 834px;
  background: white;
}
.side {
  width: 314px;
}
.category-list {
  overflow-x: auto;
  
}
::-webkit-scrollbar {
  background-color: #fff;
  width: 1px;
  height: 1px;
}
.category-name {
  color: #666;
  font-size: 1.6rem;
  height: 60px;
  line-height: 60px;
  flex-shrink: 0;
}
.category-name.active {
  color: #ff4c61;
  font-weight: bold;
}
</style>
