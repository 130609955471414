<template>
  <div>
    <router-link :to="{name: 'Detail', query: card}">
      <div class="baoliao-item flex flex-main-between pl-25 pr-25 pt-20 pb-20">
        <div class="mr-20"><img :src="card.img" alt="" class="block" style="width: 18rem; height: 18rem;border-radius: 3px;"></div>
        <div class="flex flex-column flex-main-between" style="width: calc(100% - 20rem)">
          <div class="text-4a fs-18 fw-700 lh-25">{{card.title}}</div>
          <div class="">
            <div style="background-color: #FFF1F1;padding: .5rem .8rem; border-radius: .5rem;" class="text-primary fs-15 lh-15 inline-block mb-20">{{card.desc}}</div>
            <div class="fs-22 lh-22 fw-700 text-primary mb-5" v-if="card.price != ''">{{card.price}}元</div>
            <div class="flex flex-main-between flex-cross-end">
              <div>
                <div class="text-9b fs-14">{{card.platformName}} <span class="ml-20 text-primary ">#{{card.tags}}</span></div>
              </div>
              <div style="background-color: #FF0520; width: 8rem; height: 3.4rem;line-height: 3.4rem; border-radius: 2rem;" class="text-center text-white fs-16">看详情</div>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
  
</template>
<script>
const tagName = {
    "1": "淘宝",
    "2": "天猫",
    "3": "拼多多"
  }
export default {
  name: "ListItem",
  props: {
    item: Object,
  },
  data() {
     return {
       card: {

       },
     }
  },
  created(){
    var item = this.$props.item

    // 3052清单类型认为是商品
    if(item.qingdanId == 3052) {
      item.styleType = 0 // 小图
      item.isValid = 1 // 未过期
      item.handPrice = parseFloat(Number(item.zkFinalPrice - item.denominations).toFixed(2))
      item.tag = tagName[item.platform]
      item.huangtiao = item.couponInfo
      item.goods = {
        title: item.title,
        num_iid: item.numIid,
        zk_final_price: item.zkFinalPrice,
        item_url: item.item_url,
        platform: item.platform,
        small_pic: item.small_pic,
        click_url: item.item_url
      }
     
    }

    // 商品类型
    if(item.goods) {
      this.card = {
        img: item.pic || item.goods.pic,
        title: item.goods.title,
        desc: item.huangtiao,
        price: item.handPrice ? item.handPrice : item.goods.zk_final_price,
        platformName: item.tag,
        dateStr: this.$moment(item.starttime).format('M月D日'),
        tags: item.category,
        detailImages: item.goods.small_pic,
        clickUrl: item.goods.click_url
      }
    }

    

    // 清单类型
    if(item.publishContent) {
      let pic = item.pic
      if(item.publishContent.goodsArticleIds != null && item.publishContent.goodsArticleIds.length) {
        pic = item.publishContent.goodsArticleIds[0].pic
      }
      if(item.publishContent.contentPic) {
        pic = item.publishContent.contentPic.split(",")[0]
      }
      this.card = {
        img: pic ,
        title: item.title,
        desc: item.huangtiao,
        price: '',
        platformName: item.tag,
        dateStr: this.$moment(item.starttime).format('M月D日'),
        tags: item.category
      }
    }

    // 文章类型
    if(item.article) {
      let pic
      if (item.pic) {
        pic = item.pic
      } else if (item.article.imageList && item.article.imageList.length) {
        pic = item.article.imageList[0]
      }

      this.card = {
        img: pic ,
        title: item.title,
        desc: item.huangtiao,
        price: '',
        platformName: item.tag,
        dateStr: this.$moment(item.starttime).format('M月D日'),
        tags: item.category
      }
    }
  },
  computed:{
  },
  methods:{
  },
}
</script>
<style lang="css" scoped>

</style>
